import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faStackOverflow, faGithub } from '@fortawesome/free-brands-svg-icons';
import { defineNuxtPlugin } from "#app";

config.autoAddCss = false;
library.add(fas, faLinkedin, faInstagram, faStackOverflow, faGithub);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});