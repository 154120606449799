import components_plugin_KR1HBZs4kY from "/home/runner/work/personal-website/personal-website/website/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/personal-website/personal-website/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/personal-website/personal-website/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/personal-website/personal-website/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/personal-website/personal-website/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/home/runner/work/personal-website/personal-website/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import fontawesome_klhsrycjcK from "/home/runner/work/personal-website/personal-website/website/plugins/fontawesome.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  fontawesome_klhsrycjcK
]