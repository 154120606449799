<template>
  <header class="w-full h-16 bg-indigo-500 dark:bg-indigo-800 flex flex-row items-center p-4 text-indigo-50">
    <section>
      <h2 class="text-4xl">Liel's Corner</h2>
    </section>
  </header>
</template>

<script setup>
</script>

<style scoped>

</style>