import { default as index3IVcqACT2AMeta } from "/home/runner/work/personal-website/personal-website/website/pages/index.vue?macro=true";
export default [
  {
    name: index3IVcqACT2AMeta?.name ?? "index",
    path: index3IVcqACT2AMeta?.path ?? "/",
    meta: index3IVcqACT2AMeta || {},
    alias: index3IVcqACT2AMeta?.alias || [],
    redirect: index3IVcqACT2AMeta?.redirect || undefined,
    component: () => import("/home/runner/work/personal-website/personal-website/website/pages/index.vue").then(m => m.default || m)
  }
]